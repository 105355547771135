import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { getEnvFromHost } from 'utils/env';
import { DatadogConfig } from 'store/config';

let initialized = false;

export const initializeDatadog = async (config: DatadogConfig) => {
  if (__NODE_ENV__ === 'production' && !initialized) {
    initialized = true;

    const env = getEnvFromHost();
    const version = __VERSION__;

    const { logs, rum } = config;

    const { defaultPrivacyLevel: dpl, enabled: rumEnabled, team: rumTeam, ...rumConfig } = rum;
    const defaultPrivacyLevel = dpl as DefaultPrivacyLevel;

    const regex = new RegExp(config.botPattern, 'i');
    const shouldFilterBots = regex.test(navigator.userAgent);

    if (rumEnabled) {
      const { datadogRum } = await import('@datadog/browser-rum');
      datadogRum.init({
        ...rumConfig,
        defaultPrivacyLevel,
        env,
        sessionSampleRate: shouldFilterBots ? 0 : rumConfig.sessionSampleRate,
        version,
      });
      datadogRum.setGlobalContext({
        team: rumTeam,
      });
    }

    const { enabled: logsEnabled, team: logsTeam, ...logsConfig } = logs;

    if (logsEnabled) {
      const { datadogLogs } = await import('@datadog/browser-logs');
      datadogLogs.init({
        ...logsConfig,
        env,
        sessionSampleRate: shouldFilterBots ? 0 : logsConfig.sessionSampleRate,
        version,
      });
      datadogLogs.setGlobalContext({
        team: logsTeam,
      });
    }
  }
};
