import { useEffect, useState } from 'react';
import { selectFeatureFlags } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { getDeviceTTSEnabled } from 'utils/platform';
import { getTextToSpeechEnabledStorage } from 'utils/storage';

export const useTTSEnabled = () => {
  const { hasTextToSpeech } = useAppSelector(selectFeatureFlags);
  const devSettingsTTSEnabled = getTextToSpeechEnabledStorage();

  const [deviceTTSEnabled, setDeviceTTSEnabled] = useState<boolean>(false);

  useEffect(() => {
    const fetchDeviceTTSEnabled = async () => {
      setDeviceTTSEnabled(await getDeviceTTSEnabled());
    };

    fetchDeviceTTSEnabled();
  }, []);

  const ttsEnabled = devSettingsTTSEnabled || (hasTextToSpeech && deviceTTSEnabled);

  return ttsEnabled;
};
