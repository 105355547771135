import { setUserId } from '@convivainc/conviva-js-appanalytics';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Experience } from '@mlbtv-clients/services';
import OktaAuth, { OktaAuthOptions, SigninWithCredentialsOptions } from '@okta/okta-auth-js';
import { Spinner } from 'components/common/Spinner';
import { resetSession } from 'experience/DSS';
import { createContext, ReactNode, useCallback, useEffect, useMemo } from 'react';
import * as Api from 'services/api';
import { RequestStatus } from 'services/api';
import { StandardLoginData } from 'services/mlbAuth/types';
import { apiSlice } from 'store/apiSlice';
import { selectConfigEnv, setConfigEnv, setDevExperience } from 'store/app';
import {
  fetchConfigFlow,
  fetchTtsConfigFlow,
  selectConfigStatus,
  selectServices,
} from 'store/config';
import { registerMLBSessionFlow, selectExperience, selectExperienceStatus } from 'store/experience';
import { authenticatedFlow, nonAuthenticatedFlow } from 'store/flows';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsAuthenticated, setMlbAuth, signOut } from 'store/mlbAuth';
import { isNonProdBuild } from 'utils/env';
import { getConfigEnvStorage, getDevExperienceStorage, setCacheBustStorage } from 'utils/storage';

const { ERROR, IDLE, SUCCESS } = RequestStatus;

interface IAuthContext {
  authClient: OktaAuth | null;
  logOut: () => Promise<void>;
  setTokensFromExpressLogin: (auth: StandardLoginData) => Promise<void>;
  signIn: (credentials: SigninWithCredentialsOptions) => Promise<void>;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export function AuthProvider({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const { mlbAuth } = useAppSelector(selectServices);
  const configEnv = useAppSelector(selectConfigEnv);
  const authenticated = useAppSelector(selectIsAuthenticated);
  const configStatus = useAppSelector(selectConfigStatus);
  const experience = useAppSelector(selectExperience);
  const experienceStatus = useAppSelector(selectExperienceStatus);

  const authClient = useMemo(() => {
    if (configStatus !== SUCCESS) return null;

    const { baseUrl, meta, nonProdClientId } = mlbAuth;

    const issuer = `${baseUrl}${meta.issuer}`;
    const redirectUri = `${window.location.origin}${
      __BASE_URL__ ? `/${__BASE_URL__}/` : '/'
    }login/callback`;

    const clientId =
      isNonProdBuild() && /dev|stage|prod/.test(configEnv) ? nonProdClientId : meta.clientId;

    const config: OktaAuthOptions = {
      clientId,
      issuer,
      redirectUri,
      responseType: 'code',
      scopes: ['offline_access', 'openid'],
    };

    const client = new OktaAuth(config);

    return client;
  }, [configEnv, configStatus, mlbAuth]);

  useEffect(() => {
    async function initializeAuthStateManager(client: OktaAuth) {
      client.authStateManager.subscribe((authState) => {
        const { isAuthenticated } = authState;
        const accessToken = authState.accessToken?.accessToken ?? '';
        const idToken = authState.idToken?.idToken ?? '';
        const oktaId = idToken ? client.token.decode(idToken)?.payload?.sub : '';

        dispatch(setMlbAuth({ accessToken, isAuthenticated, oktaId }));
      });

      if (client.token.isLoginRedirect()) {
        const { tokens } = await client.token.parseFromUrl();
        client.tokenManager.setTokens(tokens);
      }

      client.start();
    }

    if (authClient) {
      initializeAuthStateManager(authClient);
    }
    return () => authClient?.stop();
  }, [authClient, dispatch]);

  const signIn = useCallback(
    async (credentials: SigninWithCredentialsOptions) => {
      const transaction = await authClient?.signInWithCredentials(credentials);

      if (transaction?.status === 'SUCCESS') {
        return authClient?.token.getWithRedirect({
          sessionToken: transaction.sessionToken,
        });
      }

      throw new Error('Authentication Error: The transaction did not succeed.');
    },
    [authClient],
  );

  const logOut = useCallback(async () => {
    await Promise.allSettled([
      authClient?.revokeAccessToken(),
      authClient?.revokeRefreshToken(),
      authClient?.closeSession(),
    ]);

    if (experience === Experience.LEGACY) {
      await resetSession();
    }

    dispatch(signOut());

    if (experience === Experience.MLB) {
      await dispatch(registerMLBSessionFlow());
    }

    apiSlice.util.invalidateTags(['EPG']);
    setCacheBustStorage();
    datadogRum.clearUser();
    datadogLogs.clearUser();
    setUserId();
  }, [authClient, dispatch, experience]);

  const setTokensFromExpressLogin = useCallback(
    async (auth: StandardLoginData) => {
      if (!authClient) return;

      const {
        access_token: accessToken,
        id_token: idToken,
        refresh_token: refreshToken,
        token_type: tokenType,
      } = auth;

      const accessClaims = authClient.token.decode(accessToken).payload;
      const clientId = authClient.options.clientId ?? mlbAuth.nonProdClientId;
      const idClaims = authClient.token.decode(idToken).payload;
      const scopes = auth.scope.split(' ');
      const issuer = authClient.options.issuer ?? '';
      const authorizeUrl = authClient.options.authorizeUrl ?? '';
      const userinfoUrl = authClient.options.userinfoUrl ?? '';
      const defaultExpiresAt = Date.now() + 60 * 60 * 1000;
      const accessExpiresAt = accessClaims.exp ?? defaultExpiresAt;
      const idExpiresAt = idClaims.exp ?? defaultExpiresAt;
      const refreshExpiresAt = defaultExpiresAt * 24 * 90;

      const tokenManagerAccessToken = {
        accessToken,
        authorizeUrl,
        claims: accessClaims,
        expiresAt: accessExpiresAt,
        scopes,
        tokenType,
        userinfoUrl,
      };
      const tokenManagerIdToken = {
        authorizeUrl,
        claims: idClaims,
        clientId,
        expiresAt: idExpiresAt,
        idToken,
        issuer,
        scopes,
      };
      const tokenManagerRefreshToken = {
        authorizeUrl,
        expiresAt: refreshExpiresAt,
        issuer,
        refreshToken,
        scopes,
        tokenUrl: `${issuer}/v1/token`,
      };

      authClient.tokenManager.setTokens({
        accessToken: tokenManagerAccessToken,
        idToken: tokenManagerIdToken,
        refreshToken: tokenManagerRefreshToken,
      });
      const isAuthenticated = authClient.authStateManager.getAuthState()?.isAuthenticated;
      const oktaId = idToken ? authClient.token.decode(idToken)?.payload?.sub : '';

      dispatch(setMlbAuth({ accessToken, isAuthenticated, oktaId }));
    },
    [authClient, dispatch, mlbAuth.nonProdClientId],
  );

  useEffect(() => {
    async function fetchConfig() {
      if (isNonProdBuild()) {
        dispatch(setDevExperience(getDevExperienceStorage()));
        dispatch(setConfigEnv(getConfigEnvStorage()));
      }
      const config = await dispatch(fetchConfigFlow()).unwrap();
      await dispatch(fetchTtsConfigFlow()).unwrap();
      Api.init(config, true);
    }

    if (configStatus === IDLE) {
      fetchConfig();
    }
  }, [configStatus, dispatch]);

  useEffect(() => {
    async function runAppFlows() {
      authenticated ? await dispatch(authenticatedFlow()) : await dispatch(nonAuthenticatedFlow());
    }

    if (authenticated !== undefined && configStatus === SUCCESS && experienceStatus === IDLE) {
      runAppFlows();
    }
  }, [configStatus, dispatch, experienceStatus, authenticated]);

  const value = useMemo(
    () => ({ authClient, logOut, setTokensFromExpressLogin, signIn }),
    [authClient, logOut, setTokensFromExpressLogin, signIn],
  );

  const authComplete =
    authenticated !== undefined && (experienceStatus === SUCCESS || experienceStatus === ERROR);

  return (
    <AuthContext.Provider value={value}>
      {authComplete ? children : <Spinner variant="fullScreen" />}
    </AuthContext.Provider>
  );
}
