import { useCallback, useEffect, useState } from 'react';
import { getArrowDirectionText } from './utils';
import { ARROW_KEYS } from 'constants/spatialNavigation';
import { useTextToSpeech, useTTSEnabled } from 'hooks';
import { utteranceListFromElement } from 'utils/textToSpeech/utteranceListFromElement';
import { without } from 'lodash';
import { getTextToSpeechStrategyStorage } from 'utils/storage';
import { TTSStrategy } from 'utils/textToSpeech/types';

const { ScreenReader } = TTSStrategy;

export const useTTSNavigation = () => {
  const [prevActiveElement, setPrevActiveElement] = useState<Element | null>(null);
  const [prevUtteranceList, setPrevUtteranceList] = useState<string[]>([]);

  const textToSpeechStrategy = getTextToSpeechStrategyStorage();
  const ttsEnabled = useTTSEnabled();

  const { speak, t } = useTextToSpeech();

  const handleKeyup = useCallback(
    (e: KeyboardEvent) => {
      if (!ttsEnabled) return;

      let utteranceToSpeak = '';
      const isArrowPress = ARROW_KEYS.has(e.keyCode);
      const focusChanged = prevActiveElement !== document.activeElement;
      const liveRegion = document.getElementById('aria-live-region');

      // focus did not change
      if (isArrowPress && !focusChanged) {
        const arrowDirectionText = getArrowDirectionText(e.keyCode);
        utteranceToSpeak = t(`noSelectableContent.${arrowDirectionText}`);
      }

      // focus changed
      if (focusChanged && e.target instanceof HTMLElement) {
        const newUtteranceList = utteranceListFromElement(e.target);
        utteranceToSpeak = without(newUtteranceList, ...prevUtteranceList).join(' -- ');
        setPrevUtteranceList(newUtteranceList);
      }

      if (utteranceToSpeak) {
        if (liveRegion && textToSpeechStrategy === ScreenReader) {
          liveRegion.textContent = utteranceToSpeak;
        } else {
          speak(utteranceToSpeak);
        }
      }

      setPrevActiveElement(document.activeElement);
    },
    [prevActiveElement, prevUtteranceList, speak, t, textToSpeechStrategy, ttsEnabled],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    return () => {
      window.removeEventListener('keyup', handleKeyup);
    };
  }, [handleKeyup]);
};
