import debounce from 'lodash/debounce';

/**
 * This will use the browser's speechSynthesis to speak a phrase
 * @param phrase: The phrase to be spoken
 */
export const speakImmediately = (phrase: string, lang = 'en') => {
  if (window.speechSynthesis) {
    window.speechSynthesis.cancel();
    const utterance = new SpeechSynthesisUtterance(phrase);
    utterance.lang = lang;
    window.speechSynthesis.speak(utterance);

    try {
      window.speechSynthesis.resume();
    } catch (e) {
      console.error(e);
    }
  }
};

export const speakWithTimeout = (phrase: string, delay = 500) => {
  setTimeout(() => speakImmediately(phrase), delay);
};

/**
 * Most of the time we want to debounce the speechSynthesis so the
 * phrase is not repeated when called repetitively
 */
export const speak = debounce(speakImmediately, 100, {
  leading: true,
  trailing: false,
});

export default {
  speak,
  speakImmediately,
  speakWithTimeout,
};
